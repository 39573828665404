import { dbCollections } from "@/utils/firebaseCollections";
import firebase from "@/utils/firebaseInit"
const db = firebase.firestore();
export default {
  namespaced: true,
  state: {
    userData: [],
  },
  getters: {
    allList4xData: state => state.userData,
  },
  mutations: {
    setUserData(state, payload) {
      state.userData = payload;
    },
  },
  actions: {
    user4xData(state){
      return new Promise((resolve, reject) => {
        let userAllData = [];
        try {
          // getCollectionData(`${dbCollections.USERS}`,0,0,{field:"createdAt",value:-1})
          db.collection(dbCollections.FOURX)
          .orderBy('createdAt','desc')
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.empty) {
              resolve("No Data Found.");
            }
            else {
              querySnapshot.forEach(doc => {
                if(doc.data()){
                  userAllData.push(doc.data());
                }
                state.commit("setUserData", userAllData);

                if (userAllData.length == 0) {
                    resolve("No Data Found.");
                }
                else {
                    resolve("Records Found.");
                };
               
              })
            }
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
        } catch (error) {
          reject(error);
        }
      })
    },
  },
}

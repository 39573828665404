
import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollections"
const db = firebase.firestore();


export default {
namespaced: true,
  state: {
    advertisement: []
  },
  getters: {
    getAdvertisement: state=> state.advertisement
  },
  mutations: {
    setadvertisementArray(state,payload){
        state.advertisement = payload
    }
  },
  actions: {
    advertisementData(state){
        return new Promise((resolve, reject) => {
            try{
                let advertisementArray = [];
                firebase.auth().onAuthStateChanged((user) => {
                    // let user = firebase.auth().currentUser;
                    if (user) {
                        db.collection(dbCollections.ADVERTISMENTS).onSnapshot((resp)=>{
                            advertisementArray = [];
                            if(resp.empty){
                                resolve("No Data Found.");
                            } else {
                                resp.forEach((data)=>{
                                    advertisementArray.push(data.data())
                                })
                                // console.log(advertisementArray,'advertisementArray');
                                state.commit("setadvertisementArray",advertisementArray)
                                if (advertisementArray.length) {
                                    resolve("Data Found")
                                } else {
                                    resolve("No Data Found")
                                }
                            }
                        })
                    } else {
                        state.commit("setadvertisementArray",[])
                        resolve("All Ok")
                    }
                })
            } catch (error) {
                console.error(error)
                reject()
            }
        })
    }
  }
};
const dbCollections = {
    USERS: 'users',
    DRAFPROPERTY: 'draftProperty',
    PROPERTY:'property',
    SETTINGS: 'setting',
    TYPESENSE_PROPERTIES :'property',
    OFFERS : 'offers',
    REALESTATE :'realEstate',
    TRANSACTION_HISTORY:'transactionHistory',
    ADVERTISMENTS:'advertisments',
    SUBSCRIPTION_PRICE:'subscriptionPrice',
    FOURX:'4x'
};

const storageCollections = {
    BANNERS: "banners",
    PURCHASEAGREEMENTUPLOAD:"purchase Agreement"
};
module.exports = {
    dbCollections,
    storageCollections
}

import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollections"
const db = firebase.firestore();
import moment from "moment"

export default {
namespaced: true,
  state: {
    property: [],
    count: 0,
    idSpecificProperty:[],
    allPropertyArray:[],
    lastSixMonthPropertyArray:{},
    lastSixMonthSubscriptionArray:{}
  },
  getters: {
    getProperty: state=> state.property,
    getIdSpecificProperty : state => state.idSpecificProperty,
    getAllProperty : state => state.allPropertyArray,
    lastSixMonthPropertyData : state => state.lastSixMonthPropertyArray,
    lastSixMonthSubscriptionData : state => state.lastSixMonthSubscriptionArray
  },
  mutations: {
    setpropertyArray(state,payload){
        state.property = payload
    },
    setidspecificpropertyArray(state,payload){
        state.idSpecificProperty = payload
    },
    setallpropertyArray(state, payload){
        state.allPropertyArray = payload
    },
    setLastSixMonthPropertyData(state, payload){
        state.lastSixMonthPropertyArray = payload
    },
    setLastSixMonthSubscriptionData(state, payload){
        state.lastSixMonthSubscriptionArray = payload
    },
    updateAllPropertyArray(state, payload){
        let index = state.allPropertyArray.findIndex((val) =>{
            return val.id == payload.id
        });
        if(index != -1){
           Object.keys(payload).forEach((keyVal) =>{
                state.allPropertyArray[index][keyVal] = payload[keyVal]
           })
        }
    },
    updatePropertyData(state, payload){
        let index = state.property.findIndex((val) =>{
            return val.id == payload.id
        });
        if(index != -1){
           Object.keys(payload).forEach((keyVal) =>{
                state.property[index][keyVal] = payload[keyVal]
           })
        }
    }
  },
  actions: {
    propertyData(state,payload){
        // console.log(payload)
        return new Promise((resolve, reject) => {
            try{
                let propertyArray = [];
                firebase.auth().onAuthStateChanged((user) => {
                    // let user = firebase.auth().currentUser;
                    if (user) {
                        db.collection(dbCollections.PROPERTY)
                        .where('userId','==',payload)
                        .get().then((resp)=>{
                            if(resp.empty){
                                resolve("No Data Found.");
                            } else {
                                resp.forEach((data)=>{
                                    propertyArray.push(data.data())
                                })
                                state.commit("setpropertyArray",propertyArray)
                                if (propertyArray.length) {
                                    resolve("Data Found")
                                } else {
                                    resolve("No Data Found")
                                }
                            }
                        })
                    } else {
                        state.commit("setpropertyArray",[])
                        // state.commit("setMyCount",0)
                        resolve("All Ok")
                    }
                })
            } catch (error) {
                console.error(error)
                reject()
            }
        })
    },
    idSpecificPropertyData(state,payload){
        return new Promise((resolve, reject) => {
            try{
                let propertyArray = [];
                firebase.auth().onAuthStateChanged((user) => {
                    // let user = firebase.auth().currentUser;
                    if (user) {
                        db.collection(dbCollections.PROPERTY)
                        .where('id','==',payload)
                        .get().then((resp)=>{
                            if(resp.empty){
                                state.commit("setidspecificpropertyArray",propertyArray)
                                resolve("No Data Found.");
                            } else {
                                resp.forEach((data)=>{
                                    propertyArray.push(data.data())
                                })
                                // console.log(propertyArray,'propertyArray');
                                state.commit("setidspecificpropertyArray",propertyArray)
                                if (propertyArray.length) {
                                    resolve("Data Found")
                                } else {
                                    resolve("No Data Found")
                                }
                            }
                        })
                    } else {
                        state.commit("setpropertyArray",[])
                        // state.commit("setMyCount",0)
                        resolve("All Ok")
                    }
                })
            } catch (error) {
                console.error(error)
                reject()
            }
        })
    },
    allPropertyData(state){
        return new Promise((resolve, reject) => {
            try{
                let propertyArray = [];
                firebase.auth().onAuthStateChanged((user) => {
                    // let user = firebase.auth().currentUser;
                    if (user) {
                        db.collection(dbCollections.PROPERTY).orderBy('createdAt','desc')
                        .get().then((resp)=>{
                            if(resp.empty){
                                state.commit("setallpropertyArray",propertyArray)
                                resolve("No Data Found.");
                            } else {
                                resp.forEach((data)=>{
                                    propertyArray.push(data.data())
                                })
                                // console.log(propertyArray,'propertyArray');
                                state.commit("setallpropertyArray",propertyArray)
                                if (propertyArray.length) {
                                    resolve("Data Found")
                                } else {
                                    resolve("No Data Found")
                                }
                            }
                        })
                    } else {
                        state.commit("setpropertyArray",[])
                        // state.commit("setMyCount",0)
                        resolve("All Ok")
                    }
                })
            } catch (error) {
                console.error(error)
                reject()
            }
        })
    },
    getLastSixMonthPropertyData(state,payload){
        return new Promise((resolve, reject) => {
          let lastSixMonthPropertyDataArray = {};
          let lastSixMonthSubscriptionDataArray = {};
          let propertyData = payload;
          let startMonth = '';
          let Endmonth = '';
          let BasicDate = new Date(new Date(new Date().setMonth(10)).setFullYear(2022));
          try{
            let getData = new Date(new Date().setMonth((new Date().getMonth())));
            let basicData = new Date(new Date(BasicDate).setMonth((new Date(BasicDate).getMonth()+5)));
            let beforeSixMonthSData = new Date(new Date(getData).setMonth((new Date(getData).getMonth()-5)));
  
            let a = moment(getData);
            let b = moment(BasicDate);
            let different  = a.diff(b, 'month');
  
            if(different < 6 ){
               startMonth =new Date(BasicDate).getTime();
               Endmonth = new Date(basicData).getTime();
            }else{
              startMonth =new Date(beforeSixMonthSData).getTime();
              Endmonth = new Date(getData).getTime();
            }
  
            let totalMonthGet = [];
            let totalMonthCharts = [];
            if(startMonth == Endmonth){
                let datToAdd = moment(startMonth).format("YYYY-MM");
                totalMonthGet.push(datToAdd);
                totalMonthCharts.push(moment(startMonth).format('MMM-YY'));
            }else{
                while(startMonth < Endmonth){
                    let datToAdd = moment(startMonth).format("YYYY-MM");
                    totalMonthGet.push(datToAdd);
                    totalMonthCharts.push(moment(startMonth).format('MMM-YY'));
                    var newDate = moment(startMonth).add(1,'month')
                    let date =  newDate.toDate();
                    startMonth = date.getTime();
                }
                let datToAddEnd = moment(Endmonth).format("YYYY-MM");
                    totalMonthGet.push(datToAddEnd);
                    totalMonthCharts.push(moment(Endmonth).format('MMM-YY'));
            }
            let monthArray = {};
            let subscriptionArray = {};
            if(propertyData.length > 0){
              totalMonthGet.forEach(monthData => {
                propertyData.forEach(property => {
                  if(moment(property.createdAt.seconds*1000).format("YYYY-MM") === monthData && property.status == 2 || moment(property.createdAt.seconds*1000).format("YYYY-MM") === monthData && property.status == 6){
                    if(monthArray[monthData]=== undefined){
                      monthArray[monthData] = 1
                    }else{
                      monthArray[monthData] += 1
                    }
                  }else{
                    if(monthArray[monthData]=== undefined){
                      monthArray[monthData] = 0
                    }
                  }
                  if(moment(property.createdAt.seconds*1000).format("YYYY-MM") === monthData && property.subscriptionStatus == 1){
                    if(subscriptionArray[monthData]=== undefined){
                        subscriptionArray[monthData] = 1
                    }else{
                        subscriptionArray[monthData] += 1
                    }
                  }else{
                    if(subscriptionArray[monthData]=== undefined){
                        subscriptionArray[monthData] = 0
                    }
                  }
                })
              })
              lastSixMonthPropertyDataArray = {month: totalMonthCharts, data:Object.values(monthArray)};
              lastSixMonthSubscriptionDataArray = {month: totalMonthCharts, data:Object.values(subscriptionArray)}
              state.commit("setLastSixMonthPropertyData", lastSixMonthPropertyDataArray);
              state.commit("setLastSixMonthSubscriptionData", lastSixMonthSubscriptionDataArray);
  
              if (lastSixMonthPropertyDataArray || lastSixMonthSubscriptionDataArray) {
                  resolve("No Data Found.");
              }
              else {
                  resolve("Records Found.");
              };
            }else{
              resolve("No Data Found.");
            }
  
          }catch(error){
            // console.log('ERROR in get property data');
            reject(error);
          }
        })
      }
  }
};
export default [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/admin/dashboard/index.vue'),
    meta: {
      pageTitles:'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user-management',
    name: 'user-management',
    component: () => import('@/views/extensions/acl/AccessControl.vue'),
    meta: {
      pageTitles:'User Management',
      Title: 'User Management',
      breadcrumb: [
        {
          text: 'User Management',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user-management/:id',
    name: 'user-management-detail',
    component: () => import('@/views/extensions/acl/userManagementDetail.vue'),
    meta: {
      pageTitles:'User Management Detail',
      Title: 'User Management Detail',
      breadcrumb: [
        {
          text: 'User Management',
          to: '/user-management',
          active: false,
          
        },
      ],
    },
  },
  {
    path: '/editUser/:id',
    name: 'editUser',
    component: () => import('@/views/extensions/acl/editUser.vue'),
    meta: {
      Title: 'editUser',
      pageTitles:'Edit User',
      breadcrumb: [
        {
          text: 'User Management',
          to: '/user-management',
          active: false,
        },
      ],
    },
  },
  {
    path: '/listing-management',
    name: 'listing-management',
    component: () => import('@/views/admin/listing-management/index.vue'),
    meta: {
      pageTitles:'Listing Management',
      breadcrumb: [
        {
          text: 'Listing Management',
          active: true,
          
        },
      ],
    },
  },
  {
    path: '/listing-management/:id',
    name: 'listing-management-detail',
    component: () => import('@/views/admin/listing-management/listingDetail.vue'),
    meta: {
      pageTitles:'Listing Management Detail',
      Title: 'listingDetailPage',
      breadcrumb: [
        {
          text: 'Listing Management',
          to: '/listing-management',
          active: false,
          
        },
      ],
    },
  },
  {
    path: '/listing-management-edit/:id',
    name: 'listing-management-edit',
    component: () => import('@/views/admin/listing-management/listingEdit.vue'),
    meta: {
      pageTitles:'Listing Management Edit',
      Title: 'listingEditPage',
      breadcrumb: [
        {
          text: 'Listing Management',
          to: '/listing-management',
          active: false,
          
        },
      ],
    },
  },
  {
    path: '/application-management',
    name: 'application-management',
    component: () => import('@/views/admin/offers-management/index.vue'),
    meta: {
      pageTitles:'Offer Management',
      breadcrumb: [
        {
          text: 'Application Management',
          active: true,
         
        },
      ],
    },
  },
  {
    path: '/application-management/:id',
    name: 'application-management-detail',
    component: () => import('@/views/admin/offers-management/offerManagementDetail.vue'),
    meta: {
      pageTitles:'Offer Management Detail',
      breadcrumb: [
        {
          text: 'Application Management',
          active: false,
          
        },
      ],
    },
  },
  {
    path: '/subscription-management',
    name: 'subscription-management',
    component: () => import('@/views/admin/subscription-management/subScription.vue'),
    meta: {
      pageTitles:'Subscription Management',
      breadcrumb: [
        {
          text: 'Subscription Management',
          active: true,
        },
      ],
    },
  },
  {
    path: '/advertisment-management',
    name: 'advertisment-management',
    component: () => import('@/views/admin/advertisment-management/index.vue'),
    meta: {
      pageTitles:'Advertisement Management',
      breadcrumb: [
        {
          text: 'Advertisement Management',
          active: true,
         
        },
      ],
    },
  },
  {
    path: '/subscription-pricing',
    name: 'subscription-pricing',
    component: () => import('@/views/admin/subscription-management/subscriptionPricing.vue'),
    meta: {
      pageTitles:'Subscription Pricing',
      breadcrumb: [
        {
          text: 'Subscription Pricing',
          active: true,
          
        },
      ],
    },
  },
  {
    path: '/myprofile',
    name: 'myprofile',
    component: () => import('@/views/admin/myProfile.vue'),
    meta: {
      pageTitles:'My Profile',
      breadcrumb: [
        {
          text: 'My Profile',
          active: true,
          
        },
      ],
    },
  },
  {
    path: '/changepassword',
    name: 'changepassword',
    component: () => import('@/views/admin/changePassword.vue'),
    meta: {
      pageTitles:'Change Password',
      breadcrumb: [
        {
          text: 'Change your password',
          active: true,
          
        },
      ],
    },
  },
  // {
  //   path: '/purchase-agreement-upload',
  //   name: 'purchase-agreement-upload',
  //   component: () => import('@/views/admin/purchaseAgreement/purchaseAgreementUpload.vue'),
  //   meta: {
  //     pageTitles:'Purchase Agreement Upload',
  //     breadcrumb: [
  //       {
  //         text: 'Purchase Agreement Upload',
  //         active: true,
          
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/4x-Customer-List',
  //   name: '4x-Customer-List',
  //   component: () => import('@/views/admin/listOf4xCustomer/listingOf4xCustomer.vue'),
  //   meta: {
  //     pageTitles:'4x Customer List',
  //     breadcrumb: [
  //       {
  //         text: '4x Customer List',
  //         active: true,
          
  //       },
  //     ],
  //   },
  // },
]

import { getCollectionData } from "@/utils/FirebaseQueries/getQueries";
import { dbCollections } from "@/utils/firebaseCollections";
export default {
  namespaced: true,
  state: {
    status: [],
    offerStatus: [],
    checkThatAllApply:[],
    userStatus:[],
    subStatus:[],
    advertisementStatus:[],
    subPrice:[],
    purchaseAgreementUpload:{}
  },
  getters: {
    statusGetter: state => state.status,
    offerStatusGetter: state => state.offerStatus,
    checkThatAllApply : state => state.checkThatAllApply,
    userStatus : state => state.userStatus,
    subScriptionStatus : state => state.subStatus,
    advertisementStatus : state => state.advertisementStatus,
    subscriptionPrice : state => state.subPrice,
    purchaseAgreementUpload : state => state.purchaseAgreementUpload
  },
  mutations: {
    setStatus(state, payload) {
      state.status = payload;
    },
    setOfferStatus(state, payload) {
      state.offerStatus = payload;
    },
    setcheckThatAllApplyStatus(state,payload){
      state.checkThatAllApply = payload;
    },
    setUserStatus(state,payload){
      state.userStatus = payload;
    },
    setSubStatus(state,payload){
      state.subStatus = payload;
    },
    setAdvertisementStatus(state,payload){
      state.advertisementStatus = payload;
    },
    setSubscriptionPrice(state,payload){
      state.subPrice = payload;
    },
    setPurchaseAgreementUpload(state,payload){
      state.purchaseAgreementUpload = payload;
    },
  },
  actions: {
    getStatuses(state){
      return new Promise((resolve, reject) => {
        try {
          getCollectionData(`${dbCollections.SETTINGS}>propertyStatus`)
          .then((doc) => {
            resolve(doc.data().status);
            state.commit("setStatus", doc.data().status);
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
        } catch (error) {
          reject(error);
        }
      })
    },
    getOfferStatuses(state){
      return new Promise((resolve, reject) => {
        try {
          getCollectionData(`${dbCollections.SETTINGS}>offerStatus`)
          .then((doc) => {
            resolve(doc.data().status);
            state.commit("setOfferStatus", doc.data().status);
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
        } catch (error) {
          reject(error);
        }
      })
    },
    getCheckThatAllApplyStatuses(state){
      return new Promise((resolve, reject) => {
        try {
          getCollectionData(`${dbCollections.SETTINGS}>checkThatAllApply`)
          .then((doc) => {
            resolve(doc.data().status);
            state.commit("setcheckThatAllApplyStatus", doc.data().status);
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
        } catch (error) {
          reject(error);
        }
      })
    },
    getUserStatuses(state){
      return new Promise((resolve, reject) => {
        try {
          getCollectionData(`${dbCollections.SETTINGS}>userStatus`)
          .then((doc) => {
            resolve(doc.data().status);
            // console.log(doc.data().status,'doc.data().status')
            state.commit("setUserStatus", doc.data().status);
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
        } catch (error) {
          reject(error);
        }
      })
    },
    getSubscriptionStatus(state){
      return new Promise((resolve, reject) => {
        try {
          getCollectionData(`${dbCollections.SETTINGS}>subscriptionStatus`)
          .then((doc) => {
            resolve(doc.data().status);
            // console.log(doc.data().status,'subscriptionStatus')
            state.commit("setSubStatus", doc.data().status);
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
        } catch (error) {
          reject(error);
        }
      })
    },
    getAdvertisementStatuses(state){
      return new Promise((resolve, reject) => {
        try {
          getCollectionData(`${dbCollections.SETTINGS}>advertisementStatus`)
          .then((doc) => {
            resolve(doc.data().status);
            // console.log(doc.data().status,'doc.data().status');
            state.commit("setAdvertisementStatus", doc.data().status);
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
        } catch (error) {
          reject(error);
        }
      })
    },
    getSubscriptionPrice(state){
      return new Promise((resolve, reject) => {
        try {
          getCollectionData(`${dbCollections.SETTINGS}>subscriptionPrice`)
          .then((doc) => {
            resolve(doc.data());
            // console.log(doc.data().status,'doc.data().status');
            state.commit("setSubscriptionPrice", doc.data());
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
        } catch (error) {
          reject(error);
        }
      })
    },
    getpurchaseAgreementUpload(state){
      return new Promise((resolve, reject) => {
        try {
          getCollectionData(`${dbCollections.SETTINGS}>purchaseAgreementUpload`)
          .then((doc) => {
            resolve(doc.data());
            state.commit("setPurchaseAgreementUpload", doc.data());
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
        } catch (error) {
          reject(error);
        }
      })
    }
  },
}

<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from "@core/components/scroll-to-top/ScrollToTop.vue";

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import firebase from '@/utils/firebaseInit'
import { useWindowSize, useCssVar } from "@vueuse/core";
import jwt from "jsonwebtoken";
import store from "@/store";
const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
  import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");
import Swal from 'sweetalert2'

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
  },
  watch:{
    '$route' (to) {
      document.title = to.meta.pageTitles ? ('Shelter-Registry - '+ to.meta.pageTitles) : 'Shelter-Registry'
    }
  },
  data() {
    return {
      nowTime: new Date().getTime(),
      isSpinner: true,
      idleTime:0,
    };
  },
  created() {
    // let currentTime = new Date();
    // let token = localStorage.getItem("accessToken");
    // if (token !== null) {
    //   let abc = jwt.decode(token);
    //   let expireTime = abc.exp * 1000;
    //   if (currentTime.getTime() >= expireTime) {
    //     var interval = setInterval(() => {
    //       this.$root.$emit("logOutUserAccount");
    //       clearInterval(interval);
    //     }, 3000);
    //   }
      
    // }

    if (!this.statusGetter.length) {
      this.getStatuses();
    }
    if (!this.offerStatusGetter.length) {
      this.getOfferStatuses();
    }
    if (!this.getCheckThatAllApplyStatuses.length) {
      this.getCheckThatAllApplyStatuses();
    }
    if (!this.userStatus.length) {
      this.userStatus();
    }
    if (!this.getSubscriptionStatus.length) {
      this.getSubscriptionStatus();
    }
    if (this.getSetting.length == 0) {
      this.getSetting()
    }
    if (this.getAdvertisement.length == 0) {
      this.getAdvertisement()
    }
  },

  methods: {
    ...mapActions({ getStatuses: "admin/getStatuses" }),
    ...mapActions({ getOfferStatuses: "admin/getOfferStatuses" }),
    ...mapActions({
      getCheckThatAllApplyStatuses: "admin/getCheckThatAllApplyStatuses",
    }),
    ...mapActions({ userStatus: "admin/getUserStatuses" }),
    ...mapActions({ getSubscriptionStatus: "admin/getSubscriptionStatus" }),
    ...mapActions({ getSetting : 'setting/getSettingCollection' }),
    ...mapActions({ getAdvertisement : 'admin/getAdvertisementStatuses'}),
    spinnerStop() {
      this.isSpinner = false;
    }
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    ...mapGetters({ statusGetter: "admin/statusGetter" }),
    ...mapGetters({ offerStatusGetter: "admin/offerStatusGetter" }),
    ...mapGetters({
      setting: 'setting/getSetting'
    }),
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
    currentTimer() {
      return new Date().getTime();
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();
    const { enableScrollToTop } = $themeConfig.layout;

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    return {
      skinClasses,
      enableScrollToTop,
    };
  },
  beforeDestroy() {
    this.$root.$off("logOutUserAccount");
  },
  mounted()
  {
    var self = this;
   
    // var idleInterval;
        const timerIncrement = () =>  {
          if (!firebase.auth().currentUser) {
            self.idleTime = 0;
          }
          self.idleTime = self.idleTime + 1;
          // console.log("idleTime",self.idleTime)
          if (self.idleTime >= 5) { // 45 minutes
            // console.log("Logout")
                self.$root.$emit("logOutUserAccount");
                Swal.fire({
                  title: 'You have been logged out due to inactivity.',
                  showCancelButton: false,
                  confirmButtonColor: '#3085d6',
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'SweetAlertConfirm', 
                      title: 'SweetAlertTitle'
                  }
                })
           
              
            // clearInterval(idleInterval);

            resetTimer;
          }
        }
    
        function resetTimer(){
          self.idleTime = 0;
    
        }
        // idleInterval = setInterval(timerIncrement, 60000); // 1 minute
        setInterval(timerIncrement, 60000); // 1 minute
        window.onmousemove = resetTimer;
        window.onmousedown = resetTimer;
        window.onclick = resetTimer;
        window.onscroll = resetTimer;
        window.onkeypress = resetTimer;
    // setInterval(() => {
    //   // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> JJJ');
    // }, 1000);

    

    // firebase.auth().onAuthStateChanged(user=>{
    //   console.log('user', user);
    //   if(user)
    //   {
    //     var idleInterval;
    //     // console.log("User Found!!")
    //     function timerIncrement() {
    //       self.idleTime = self.idleTime + 1;
    //       // console.log('>>>>>>>>>>>>>>>>>>>>>>>>> H1', self.idleTime);
    //       // console.log("idleTime",self.idleTime)
    //       if (self.idleTime >= 10) { // 45 minutes
    //         // console.log("Logout")
    //         Swal.fire({
    //           title: 'Account is logout due to inactivity.',
    //           showCancelButton: false,
    //           confirmButtonColor: '#3085d6',
    //           confirmButtonText: 'Ok',
    //           customClass: {
    //               confirmButton: 'SweetAlertConfirm', 
    //               title: 'SweetAlertTitle'
    //           }
    //         })
    //          self.$root.$emit("logOutUserAccount");

    //         clearInterval(idleInterval);

    //         resetTimer;
    //       }
    //     }
    
    //     function resetTimer() {
    //       self.idleTime = 0;
    
    //     }
    //     idleInterval = setInterval(timerIncrement, 2000); // 1 minute
    //     window.onmousemove = resetTimer;
    //     window.onmousedown = resetTimer;
    //     window.onclick = resetTimer;
    //     window.onscroll = resetTimer;
    //     window.onkeypress = resetTimer;
    //   }
    // })

  },
};
</script>

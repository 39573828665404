
import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollections"
const db = firebase.firestore();


export default {
namespaced: true,
  state: {
    transactionArray: []
  },
  getters: {
    getTransactionHistory: state=> state.transactionArray
  },
  mutations: {
    setTransactionarr(state,payload){
        state.transactionArray = payload
    }
  },
  actions: {
    transactions(state,payload){
        return new Promise((resolve, reject) => {
            try{
                let transactionArray = [];
                firebase.auth().onAuthStateChanged((user) => {
                    // let user = firebase.auth().currentUser;
                    // console.log(user.uid,'transactionStore')
                    if (user) {
                        db.collection(dbCollections.TRANSACTION_HISTORY).where('userId','==',payload).get().then((resp)=>{
                            if(resp.empty){
                                resolve("No Data Found.");
                            } else {
                                resp.forEach((data)=>{
                                    transactionArray.push(data.data())
                                })
                                // console.log(transactionArray,'transactionArray');
                                state.commit("setTransactionarr",transactionArray)
                                if (transactionArray.length) {
                                    resolve("Data Found")
                                } else {
                                    resolve("No Data Found")
                                }
                            }
                        })
                    } else {
                        state.commit("setTransactionarr",[])
                        resolve("All Ok")
                    }
                })
            } catch (error) {
                console.error(error)
                reject()
            }
        })
    }
  }
};
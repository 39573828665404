
import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollections"
const db = firebase.firestore();


export default {
namespaced: true,
  state: {
    offer: [],
    alloffer:[]
  },
  getters: {
    getOffer: state=> state.offer,
    getAllOffer : state => state.alloffer
  },
  mutations: {
    setoffersArray(state,payload){
        state.offer = payload
    },
    setalloffersArray(state,payload){
        state.alloffer = payload
    }
  },
  actions: {
    offerData(state,payload){
        return new Promise((resolve, reject) => {
            try{
                let offerArray = [];
                firebase.auth().onAuthStateChanged((user) => {
                    // let user = firebase.auth().currentUser;
                    if (user) {
                        db.collection(dbCollections.OFFERS).where('id','==',payload).get().then((resp)=>{
                            if(resp.empty){
                                resolve("No Data Found.");
                            } else {
                                resp.forEach((data)=>{
                                    offerArray.push(data.data())
                                })
                                // console.log(offerArray,'offerArray');
                                state.commit("setoffersArray",offerArray)
                                if (offerArray.length) {
                                    resolve("Data Found")
                                } else {
                                    resolve("No Data Found")
                                }
                            }
                        })
                    } else {
                        state.commit("setoffersArray",[])
                        resolve("All Ok")
                    }
                })
            } catch (error) {
                console.error(error)
                reject()
            }
        })
    },
    allOfferData(state){
        return new Promise((resolve, reject) => {
            try{
                let offerArray = [];
                firebase.auth().onAuthStateChanged((user) => {
                    // let user = firebase.auth().currentUser;
                    if (user) {
                        db.collection(dbCollections.OFFERS).get().then((resp)=>{
                            if(resp.empty){
                                resolve("No Data Found.");
                            } else {
                                resp.forEach((data)=>{
                                    offerArray.push(data.data())
                                })
                                // console.log(offerArray,'offerArray');
                                state.commit("setalloffersArray",offerArray)
                                if (offerArray.length) {
                                    resolve("Data Found")
                                } else {
                                    resolve("No Data Found")
                                }
                            }
                        })
                    } else {
                        state.commit("setalloffersArray",[])
                        resolve("All Ok")
                    }
                })
            } catch (error) {
                console.error(error)
                reject()
            }
        })
    }
  }
};
import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import admin from './admin';
import user from './user';
import property from './property';
import transactionHistory from './transactionHistory';
import setting from './setting';
import offer from './offer';
import advertisement from './advertisement'
import listingOf4x from './listingOf4x'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    admin,
    user,
    property,
    transactionHistory,
    setting,
    offer,
    advertisement,
    listingOf4x
  },
  strict: process.env.DEV,
})


import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollections"
const db = firebase.firestore();


export default {
namespaced: true,
  state: {
    setting: []
  },
  getters: {
    getSetting: state=> state.setting
  },
  mutations: {
    srtSettingArr(state,payload){
        state.setting = payload
    }
  },
  actions: {
    getSettingCollection(state){
        return new Promise((resolve,reject)=>{
            try{
                let setting = []
                db.collection(dbCollections.SETTINGS).get().then((resp)=>{
                    if(resp.empty){
                        resolve("No Data Found.");
                    } else {
                        resp.forEach((data)=>{
                            setting.push(data.data())
                        })
                        state.commit("srtSettingArr",setting)
                        if (setting.length) {
                            resolve("Data Found")
                        } else {
                            resolve("No Data Found")
                        }
                    }
                })
            } catch (error) {
                console.error(error)
                reject()
            }
        })
    }
  }
};
import firebase from "@/utils/firebaseInit";
const db = firebase.firestore();

export const getCollectionData = (path, limit=0, startAfter=0, orderBy={}) => {
    return new Promise((resolve, reject) => {
        try {

            if(!path)  {
                reject(new Error("Path is required"));
                return;
            }

            let splitPath = path.split(">");

            let isCollection = splitPath.length % 2 !== 0;

            let query = db;
            if(splitPath.length) {
                splitPath.forEach((value, index) => {
                    if(index % 2 !== 0) {
                        query = query.doc(value);
                    } else {
                        query = query.collection(value);
                    }
                })
            }

            if(isCollection) {
                if(Object.keys(orderBy).length) {
                    query = query.orderBy(orderBy.field, orderBy.value === 0 ? "asc" : "desc");
                }
                if(startAfter) {
                    query = query.startAfter(startAfter);
                }
                if(limit !== 0) {
                    query = query.limit(limit);
                }

               
            }

            query
            .get()
            .then((querySnapshot) => {
                resolve(querySnapshot);
            })
            .catch((error) => {
                reject(error);
            })
        } catch (error) {
            reject(error);
        }
    })
}
/******* QUERY FOR GET DATA WITH ONSNAPSHOT FROM FIRESTORE ROOT COLLECTION WITH WHERE CONDITION *******************/
export const getDataWithSpecificWhereCondition = ( collectionName, fieldName, condition, fieldValue,limitValue, cb) => {
    if(limitValue > 0){
        db.collection(collectionName).where(fieldName, condition, fieldValue).orderBy('subscriptionDate','desc').limit(limitValue).onSnapshot((snapshot) => {        
            let response = [];
            if (!snapshot.empty) {
                snapshot.forEach((doc) => {              
                    response.push({id: doc.id, ...doc.data()})
                });
                cb(response);
                return;
            } else {
                cb(response);
                return;
            }
        });
    }else{
        db.collection(collectionName).where(fieldName, condition, fieldValue).orderBy('subscriptionDate','desc').onSnapshot((snapshot) => {        
            let response = [];
            if (!snapshot.empty) {
                snapshot.forEach((doc) => {              
                    response.push({id: doc.id, ...doc.data()})
                });
                cb(response);
                return;
            } else {
                cb(response);
                return;
            }
        });
    }
}
import { getCollectionData } from "@/utils/FirebaseQueries/getQueries";
import { dbCollections } from "@/utils/firebaseCollections";
import firebase from "@/utils/firebaseInit"
const db = firebase.firestore();
import moment from "moment"
export default {
  namespaced: true,
  state: {
    userData: [],
    idSpecificuser:[],
    last6monthUser:{}
  },
  getters: {
    allUserData: state => state.userData,
    idSpecificUser: state => state.idSpecificuser,
    lastSixmonthUser: state => state.last6monthUser
  },
  mutations: {
    setUserData(state, payload) {
      state.userData = payload;
    },
    setidspecificuserArray(state, payload){
      state.idSpecificuser = payload
    },
    setLastSixMonthUserData(state, payload){
      state.last6monthUser = payload
    }
  },
  actions: {
    userData(state){
      return new Promise((resolve, reject) => {
        let userAllData = [];
        try {
          // getCollectionData(`${dbCollections.USERS}`,0,0,{field:"createdAt",value:-1})
          db.collection(dbCollections.USERS)
          .orderBy('createdAt','desc')
          .get()
          .then((querySnapshot) => {
            userAllData = [];
            if (querySnapshot.empty) {
              resolve("No Data Found.");
            }
            else {
              querySnapshot.forEach(doc => {
                if(doc.data()){
                  userAllData.push(doc.data());
                }
                state.commit("setUserData", userAllData);

                if (userAllData.length == 0) {
                    resolve("No Data Found.");
                }
                else {
                    resolve("Records Found.");
                };
               
              })
            }
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
        } catch (error) {
          reject(error);
        }
      })
    },
    idSpecificUserData(state,payload){
      return new Promise((resolve, reject) => {
        try{
            let usersArray = [];
            firebase.auth().onAuthStateChanged((user) => {
                // let user = firebase.auth().currentUser;
                if (user) {
                    db.collection(dbCollections.USERS)
                    .where('id','==',payload)
                    .get().then((resp)=>{
                        if(resp.empty){
                            state.commit("setidspecificuserArray",usersArray)
                            resolve("No Data Found.");
                        } else {
                            resp.forEach((data)=>{
                                usersArray.push(data.data())
                            })
                            // console.log(usersArray,'usersArray');
                            state.commit("setidspecificuserArray",usersArray)
                            if (usersArray.length) {
                                resolve("Data Found")
                            } else {
                                resolve("No Data Found")
                            }
                        }
                    })
                } else {
                    state.commit("setidspecificuserArray",[])
                    resolve("All Ok")
                }
            })
        } catch (error) {
            console.error(error)
            reject()
        }
    })
    },
    getLastSixMonthUsersData(state,payload){
      return new Promise((resolve, reject) => {
        let lastSixMonthUserDataArray = {};
        let userData = payload;
        let startMonth = '';
        let Endmonth = '';
        let BasicDate = new Date(new Date(new Date().setMonth(10)).setFullYear(2022));
        try{
          let getData = new Date(new Date().setMonth((new Date().getMonth())));
          let basicData = new Date(new Date(BasicDate).setMonth((new Date(BasicDate).getMonth()+5)));
          let beforeSixMonthSData = new Date(new Date(getData).setMonth((new Date(getData).getMonth()-5)));

          let a = moment(getData);
          let b = moment(BasicDate);
          let different  = a.diff(b, 'month');
          // console.log(getData,BasicDate,different,basicData);

          if(different < 6 ){
             startMonth =new Date(BasicDate).getTime();
             Endmonth = new Date(basicData).getTime();
          }else{
            startMonth =new Date(beforeSixMonthSData).getTime();
            Endmonth = new Date(getData).getTime();
          }

          let totalMonthGet = [];
          let totalMonthCharts = [];
          if(startMonth == Endmonth){
              let datToAdd = moment(startMonth).format("YYYY-MM");
              totalMonthGet.push(datToAdd);
              totalMonthCharts.push(moment(startMonth).format('MMM-YY'));
          }else{
              while(startMonth < Endmonth){
                  let datToAdd = moment(startMonth).format("YYYY-MM");
                  totalMonthGet.push(datToAdd)
                  totalMonthCharts.push(moment(startMonth).format('MMM-YY'));
                  var newDate = moment(startMonth).add(1,'month')
                  let date =  newDate.toDate();
                  startMonth = date.getTime();
              }
              let datToAddEnd = moment(Endmonth).format("YYYY-MM");
                  totalMonthGet.push(datToAddEnd);
                  totalMonthCharts.push(moment(Endmonth).format('MMM-YY'));
          }
          // console.log(totalMonthGet,'totalMonthGet',totalMonthCharts);
          let monthArray = {};
          if(userData.length > 0){
            totalMonthGet.forEach(monthData => {
              userData.forEach(user => {
                if(moment(user.createdAt.seconds*1000).format("YYYY-MM") === monthData && user.userRole != 'admin'){
                  if(monthArray[monthData]=== undefined){
                    monthArray[monthData] = 1
                  }else{
                    monthArray[monthData] += 1
                  }
                }else{
                  if(monthArray[monthData]=== undefined){
                    monthArray[monthData] = 0
                  }
                }
              })
            })
            // console.log(Object.values(monthArray),'monthArray');
            lastSixMonthUserDataArray = {month:totalMonthCharts,data:Object.values(monthArray)};
            state.commit("setLastSixMonthUserData", lastSixMonthUserDataArray);

            if (lastSixMonthUserDataArray) {
                resolve("No Data Found.");
            }
            else {
                resolve("Records Found.");
            };
          }else{
            resolve("No Data Found.");
          }

        }catch(error){
          // console.log('ERROR in get userdata');
          reject(error);
        }
      })
    }
  },
}
